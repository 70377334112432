import React from "react";
import Icon from "@mdi/react";
import classnames from "classnames";
import {
  mdiHomeOutline,
  mdiMenu,
  mdiAccountOutline,
  mdiDomain,
  mdiBellOutline,
  mdiListBoxOutline,
  mdiChevronRight,
  mdiLogout,
  mdiDotNet,
  mdiCircleSmall,
  mdiArrowRightThin,
} from "@mdi/js";
import { Link, useLocation } from "react-router-dom";

function SideBar() {
  const location = useLocation();
  const menus = [
    {
      name: "Dashboard",
      icon: mdiHomeOutline,
      href: "/dashboard",
    },
    {
      name: "Master",
      icon: mdiMenu,
      href: "/master",
      children: [
        {
          name: "Course",
          icon: mdiAccountOutline,
          href: "/course",
        },
        {
          name: "Specification",
          icon: mdiAccountOutline,
          href: "/specification",
        },
      ],
    },
    {
      name: "Advocates",
      icon: mdiAccountOutline,
      href: "/advocates",
      
    },
    {
      name: "Companies",
      icon: mdiDomain,
      href: "/companies",
    },
    {
      name: "Notifications",
      icon: mdiBellOutline,
      href: "/dashboard",
    },
    {
      name: "Plans",
      icon: mdiListBoxOutline,
      href: "/plans",
    },
  ];

  return (
    <>
      <div
        id="drawer-navigation"
        className="z-40 h-screen md:sticky py-8 px-5 bg-violet-900 w-64 top-0 fixed -left-64"
        tabIndex="-1"
        aria-labelledby="drawer-navigation-label"
      >
        <div className="">
          <p className="text-3xl font-bold font-serif text-yellow-400 underline underline-offset-4">
            Legal Datum
          </p>
          {/* <div className="absolute top-9 left-60 p-1 bg-white rounded-full border-4 border-violet-100">
            <Icon path={mdiChevronLeft} className="w-5 h-5 text-violet-900" />
          </div> */}
        </div>
        <div className="flex place-items-center pt-5 space-x-5">
          <div>
            <img
              src="/images/pic.jpg"
              alt=""
              className="w-16 h-16 rounded-full"
            />
          </div>
          <div>
            <p className="text-md font-medium text-white">{sessionStorage.getItem("Name")?sessionStorage.getItem("Name"):""}</p>
            <div className="flex space-x-1 text-white">
              <Icon path={mdiLogout} className="w-4 h-4" />
              <a href="#" className="text-sm underline" onClick={()=>{sessionStorage.clear();  window.location.href="/";}}>
                Logout
              </a>
            </div>
          </div>
        </div>
        <div className="pt-10 space-y-2">
          <div className="pt-2">
            <Link to="/app/dashboard">
              <div
                className={classnames(
                  "flex justify-between p-2 rounded-lg font-semibold",
                  location.pathname === "/" ||
                    location.pathname === "/app/dashboard"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <div className="flex justify-left space-x-2">
                  <Icon path={mdiHomeOutline} className="w-5 h-5" />
                  <p className="text-sm">Dashboard</p>
                </div>
                <Icon path={mdiChevronRight} className="w-5 h-5" />
              </div>
            </Link>
          </div>
          <div className="pt-1">
            <div className="flex justify-between p-2 rounded-lg font-semibold text-white">
              <div className="flex justify-left space-x-2">
                <Icon path={mdiMenu} className="w-5 h-5" />
                <button className="text-sm font-semibold ">Masters</button>
              </div>
              <Icon path={mdiChevronRight} className="w-5 h-5" />
            </div>

            <Link to="/app/master/course">
              <div
                className={classnames(
                  "flex space-x-2 p-2 rounded-lg font-semibold pl-5",
                  location.pathname === "/master" ||
                    location.pathname === "/app/master/course"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <Icon path={mdiArrowRightThin} className="w-5 h-5" />
                <p className="text-sm font-semibold ">Course</p>
              </div>
            </Link>
            <Link to="/app/master/specialization">
              <div
                className={classnames(
                  "flex space-x-2 p-2 rounded-lg font-semibold pl-5",
                  location.pathname === "/master" ||
                    location.pathname === "/app/master/specialization"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <Icon path={mdiArrowRightThin} className="w-5 h-5" />
                <p className="text-sm font-semibold ">Specialization</p>
              </div>
            </Link>
          </div>
          <div className="pt-1">
            <Link to="/app/advocates">
              <div
                className={classnames(
                  "flex justify-between p-2 rounded-lg font-semibold",
                  location.pathname === "/" ||
                    location.pathname === "/app/advocates"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <div className="flex justify-left space-x-2">
                  <Icon path={mdiAccountOutline} className="w-5 h-5" />
                  <p className="text-sm font-semibold ">Advocates</p>
                </div>

                <Icon path={mdiChevronRight} className="w-5 h-5" />
              </div>
            </Link>
          </div>
          <div className="pt-1">
            <Link to="/app/companies">
              <div
                className={classnames(
                  "flex justify-between p-2 rounded-lg font-semibold",
                  location.pathname === "/" ||
                    location.pathname === "/app/companies"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <div className="flex justify-left space-x-2">
                  <Icon path={mdiDomain} className="w-5 h-5" />
                  <p className="text-sm font-semibold ">Companies</p>
                </div>

                <Icon path={mdiChevronRight} className="w-5 h-5" />
              </div>
            </Link>
          </div>
          <div className="pt-1">
            <Link to="/app/notifications">
              <div
                className={classnames(
                  "flex justify-between p-2 rounded-lg font-semibold",
                  location.pathname === "/" ||
                    location.pathname === "/app/notifications"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <div className="flex justify-left space-x-2">
                  <Icon path={mdiBellOutline} className="w-5 h-5" />
                  <p className="text-sm font-semibold ">Notifications</p>
                </div>

                <Icon path={mdiChevronRight} className="w-5 h-5" />
              </div>
            </Link>
          </div>
          <div className="pt-1">
            <Link to="/app/plans">
              <div
                className={classnames(
                  "flex justify-between p-2 rounded-lg font-semibold",
                  location.pathname === "/" ||
                    location.pathname === "/app/plans"
                    ? "bg-violet-200"
                    : "text-white"
                )}
              >
                <div className="flex justify-left space-x-2">
                  <Icon path={mdiListBoxOutline} className="w-5 h-5" />
                  <p className="text-sm font-semibold ">Plans</p>
                </div>

                <Icon path={mdiChevronRight} className="w-5 h-5" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
