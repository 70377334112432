import React , { useEffect, useState }from "react";
import {
  iucourse,
  getCourseForDDL,
  getCourseDetail,
  getCourseDelete,
  getCourseDetailByID,
 } from "../config/api";
import { check_islogin } from "../config/constant";

function ImportAdvocate({ setShow, editID }) {
  const [state, setState] = useState({
    CourseID: "0",
    CourseName: "",
    
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  
  useEffect(() => {debugger;
    // BindEducationDDL();
    check_islogin();
    if (editID !== "" && editID !== "0" && editID !== 0 && typeof editID !== "undefined") {
      UpdateData(editID);
    }else{
      ResetState();
    }
  }, [editID]);

  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iucourse(state)
        .then((response) => {
          if (response.ID !== "exists") {
            if(editID==="0" || editID ===0){
            alert("Course Name Saved Successfully");
            }else{
              alert("Course Name Updated Successfully");
            }
            setShow(false);
            ResetState();
           // window.location.href = "/education-master";
            // navigate(
            //   `/app/package/add/itinerary${
            //     location.search.includes("m=e")
            //       ? location.search
            //       : "?id=" + response.ID
            //   }`
            // );
          } else {
            errors["CourseName"] = "This Course Name Already exists...";
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function UpdateData(id) {
    await getCourseDetailByID(id)
      .then((response) => {
        setState(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      CourseID: "0",
      CourseName: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.CourseName) {
      IsValid = false;
      errors["CourseName"] = "Course Name is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  return (
    <>
      <div className="space-y-1">
          <p className="text-sm">Course Name</p>
          <input 
           type="text" 
           label="Course Name"
           name="CourseName"
           data-name="CourseName"
           value={state.CourseName}
           onChange={handlechange}
          className="border border-slate-400 p-1 rounded w-full" 
          error={
            state.errors
              ? state.errors.CourseName
                ? state.errors.CourseName
                : ""
              : ""
          }
         />
        </div>
        <div className="flex justify-center space-x-2 pt-10 mx-auto">
            <button  onClick={SaveData} className="bg-violet-900 text-white py-1 px-10 rounded">Submit</button>
            <button onClick={ResetState} className="bg-yellow-500 text-white py-1 px-10 rounded">Reset</button>
        </div>
    </>
  );
}

export default ImportAdvocate;
