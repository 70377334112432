import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../layout/SideBar";

function AppLayout() {
  return (
    <>
      <div className="flex items-start h-fit">
        <SideBar />
        <div className="flex-1 py-8 px-5 md:p-10  bg-violet-100 min-h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default AppLayout;
