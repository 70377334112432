import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AppLayout from "./component/AppLayout";
import Master from "./pages/Master";
import Advocates from "./pages/Advocates";
import Companies from "./pages/Companies";
import Notifications from "./pages/Notifications";
import Plans from "./pages/Plans";
import Login from "./component/Login";
import Course from "./pages/Menu/Course";
import Specialization from "./pages/Menu/Specialization";
import AdvViewMore from "./pages/AdvViewMore";
import CompViewMore from "./pages/CompViewMore";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/app" element={<AppLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="master">
            <Route path="course" element={<Course />} />
            <Route path="specialization" element={<Specialization />} />
          </Route>
          <Route path="advocates" element={<Advocates />} />
          <Route path="advocate-view" element={<AdvViewMore />} />
          <Route path="company-view" element={<CompViewMore/>}/>
          <Route path="companies" element={<Companies />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="plans" element={<Plans />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
