import React, { useMemo, UseFilter, useState ,useEffect} from "react";
import { mdiChevronRight, mdiPencil, mdiPlus, mdiTrashCan,mdiPencilOutline, mdiTrashCanOutline  } from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import Table from "../../component/Table";
import Offcanvas from "../../component/Offcanvas";
import AddCourse from "./AddCourse";
import {
  iucourse,
  getCourseForDDL,
  getCourseDetail,
  getCourseDelete,
  getCourseDetailByID,
 } from "../../config/api";
import { check_islogin } from "../../config/constant";

function Course({ setSmShow, editID ,handleAddNew, handleEdit}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [editid, setEditID] = useState(0);
  const [state, setState] = useState({
    CourseID: "0",
    CourseName: "",
    
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  // const data = [
  //   {
  //     id:"CourseID",
  //     courseName:"CourseName",
  //     action:"action",
  //   },


    // {
    //   id: "c102",
    //   courseName: "Course Name here",
    //   action: "action",
    // },
    // {
    //   id: "c103",
    //   courseName: "Course Name here",
    //   action: "action",
    // },

 // ];
  const columns = useMemo(() => [
    {
      Header: "Course Code",
      accessor: "CourseID",
    },
    {
      Header: "Course Name",
      accessor: "CourseName",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="flex justify-start space-x-3">
          {/* <button className="flex bg-violet-900 py-1 px-3 text-white rounded">
            <Icon path={mdiPlus} className="w-5 h-5" />
            <span className="text-xs text-center pt-0.5">People</span>
          </button> */}
          <button className="">
            <Icon path={mdiPencil} className="text-green-700 w-5 h-5" 
             onClick={() => {
              setEditID(row.original.CourseID);setShow(true);
            }}

            // data-id={state.CourseID}
            // data-name="course"
            // onClick={handleEdit}
            />
          </button>
          <button className="">
            <Icon path={mdiTrashCan} className="text-red-600 w-5 h-5" 
            onClick={() => {debugger;
                  DeleteData(row);
                }}/>
          </button>
        </div>
      ),
    },
  ]);

  // const id = editID;
  // useEffect(() => {
  //   if (id !== "" && id !== "0" && id !== 0 && typeof id !== "CourseID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ") {
  //     UpdateData(id);
  //   }

 // }, []);
 check_islogin();
    bindData();
    //UpdateData();
//  }, []);

  async function bindData() {
    await getCourseDetail()
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function DeleteData(row) {
    let _id = row.original.CourseID;
    if (window.confirm("Are you sure to delete.?")) {
      await getCourseDelete(_id)
        .then((response) => {
          alert("Deleted Successfully");
          bindData();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handlechange(e) {
    setState({ ...state, [e.target.name]: [e.target.value] });
  }

  async function UpdateData(id) {
    await getCourseDetailByID(id)
      .then((response) => {
        setState(response[0][0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      CourseID: "0",
      CourseName: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-slate-500 text-xl font-semibold">Master</p>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Course</p>
      </div>

      {/* <button className='text-sm text-white bg-violet-900 rounded p-1'>Add Course</button> */}
      <div className="bg-white p-6 border shadow-sm rounded mt-5">
         <Table
          columns={columns}
         data={data}
          leftSide={
            <>
              <button
                className="bg-violet-900 text-white text-sm p-2 px-4 rounded font-medium"
                onClick={() => {                  
                  setEditID(0);
                  setShow(true);
                }}
              >
                Add Course
              </button>
            </>
          }
        />

         {/* <div className="dataTables_wrapper dt-bootstrap5 no-footer">
            {data ? <Table columns={columns} data={data} /> : <></>}
          </div> */}
      </div>

      <Offcanvas show={show} setShow={setShow} title="Add Course">
        <AddCourse editID={editid} setShow={setShow} />
      </Offcanvas>
    </>
  );
}

export default Course;
