//import React from "react";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { Card, Container, Row, Col } from "react-bootstrap";
import { getAdminLoginDetail } from "../config/api";
function Login() {

  //export default function Login() {
    const [state, setstate] = useState({
      EmailID: "",
      Password: "",
    });
    const [pwdType, setpwdType] = useState("Password");
    const handleChange = (e) => {
      setstate({ ...state, [e.target.name]: e.target.value });
    };
    useEffect(() => { }, []);
    async function handleClick(e) {
      e.preventDefault();
      debugger;
      await getAdminLoginDetail(state).then(response => {
        if (response[0].length > 0) {
          sessionStorage.setItem("UserID", response[0][0].ID);
          sessionStorage.setItem("Name", response[0][0].Name);
          sessionStorage.setItem("EmailID", response[0][0].EmailID);
          window.location.href = "/app/dashboard";
        }
        else {
          alert("EmailID not found \nPlease contact to admin");
        }
      }).catch((error) => {
        alert(error);
      });
      // if (
      //   state.EmailID === "admin@gmail.com" &&
      //   state.Password === "admin@123"
      // ) {
        // window.location.href = "/dashboard";
      // } else {
      //   alert("EmailID not found \nPlease contact to admin");
      // }
    };

  return (
    <>
      <div className="grid place-items-center bg-slate-100 py-16 min-h-screen">
        <div className="bg-white rounded border border-slate-400 p-8">
          <div className="flex justify-center">
            <img src="/images/Logo.png" alt="" className="h-12" />
          </div>
          <p className="pt-1 text-lg text-center font-bold font-serif text-blue-900 underline underline-offset-2">
            Legal Datum
          </p>
          <p className="pt-5 text-xl text-center font-medium">
            Hi, Welcome Back
          </p>
          <p className="text-slate-600 text-sm text-center">
            Enter your credentials to continue
          </p>

          <div className="pt-5">
            <div className="pt-1">
              <p className="pb-1 text-sm font-medium">Email Id</p>
              <input
                type="text"
                name="EmailID"
                placeholder="Enter Email ID"
                value={state.EmailID}
                onChange={handleChange}
                className="border border-slate-300 rounded py-1 pl-2 pr-20"
              />
            </div>
            <div className="pt-4">
              <p className="pb-1 text-sm font-medium">Password</p>
              <input
                //type="password"
                type={pwdType}
                name="Password"
                placeholder="Enter Password"
                value={state.Password}
                onChange={handleChange}
                className="border border-slate-300 rounded py-1 pl-2 pr-20"
              />
{/*              
             {pwdType === "Password" ? (
                            <Icon
                              path={mdiEye}
                              onClick={() => {
                                setpwdType("text");
                              }}
                            />
                          ) : (
                            <Icon
                              path={mdiEyeOff}
                              onClick={() => {
                                setpwdType("Password");
                              }}
                            />
                          )}
                           */}
            </div>
            <div className="pt-4">
              <Link >
                <button className="bg-blue-700 text-white text-sm p-2 rounded w-full" onClick={handleClick}>
                  SIGN IN
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
