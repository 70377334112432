import React, { useMemo, UseFilter, useState ,useEffect} from "react";
import {
  mdiChevronRight,
  mdiEye,
  mdiPencil,
  mdiTrashCan,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import Table from "../component/Table";
import Offcanvas from "../component/Offcanvas";
import AddPlans from "./AddPlans";
import {
  iuplan,
  getPlanForDDL,
  getPlanDetail,
  getPlanDelete,
  getPlanDetailByID,
 } from "../config/api";
import { check_islogin } from "../config/constant";


function Plans({ setSmShow, editID ,handleAddNew, handleEdit}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [plandata,setPlanData]=useState
  ([
   
  ]);
const [state, setState] = useState({
  PlanID: "0",
  PlanName: "",
  SubTitle:"",  
  Price:"",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  // const data = [
  //   {
  //     id: "p101",
  //     planName: "Basic",
  //     subTitle: "Sub Title here",
  //     price: "month/year",
  //     action: "action",
  //   },
  //   {
  //     id: "p102",
  //     planName: "Standard",
  //     subTitle: "Sub Title here",
  //     price: "month/year",
  //     action: "action",
  //   },
  //   {
  //     id: "p103",
  //     planName: "Premium",
  //     subTitle: "Sub Title here",
  //     price: "month/year",
  //     action: "action",
  //   },
  // ];
  const columns = useMemo(() => [
    {
      Header: "Plan Code",
      accessor: "PlanID",
    },
    {
      Header: "Plan Name",
      accessor: "PlanName",
    },
    {
      Header: "Sub Title",
      accessor: "SubTitle",
    },
    {
      Header: "Price",
      accessor: "Price",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="flex justify-start space-x-3">
          <button className="">
            <Icon path={mdiEye} className="text-blue-700 w-5 h-5" />
          </button>
          <button className="">
            <Icon path={mdiPencil} className="text-green-700 w-5 h-5" />
          </button>
          <button className="">
            <Icon path={mdiTrashCan} className="text-red-600 w-5 h-5" 
             onClick={() => {debugger;
              DeleteData(row);
            }}/>
          </button>
        </div>
      ),
    },
  ]);


  //const id = editID;
  useEffect(() => {
    // if (id !== "" && id !== "0" && id !== 0 && typeof id !== "CourseID                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ") {
    //   UpdateData(id);
    // }

 // }, []);
 check_islogin();
    bindData();
  }, []);

  async function bindData() {
    await getPlanDetail()
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function DeleteData(row) {
    let _id = row.original.PlanID;
    if (window.confirm("Are you sure to delete.?")) {
      await getPlanDelete(_id)
        .then((response) => {
          alert("Deleted Successfully");
          bindData();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handlechange(e) {
    setState({ ...state, [e.target.name]: [e.target.value] });
  }

  async function UpdateData(id) {
    await  getPlanDetailByID(id)
      .then((response) => {
        setState(response[0][0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      PlanID: "0",
      PlanName: "",
      SubTitle:"",  
      Price:"",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }




  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>

        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Plans</p>
      </div>

      {/* <button className='text-sm text-white bg-violet-900 rounded p-1'>Add Course</button> */}
      <div className="bg-white p-6 border shadow-sm rounded mt-5">
        <Table
          columns={columns}
          data={data}
          leftSide={
            <>
              <button className="bg-violet-900 text-white text-sm p-2 px-4 rounded font-medium"
               onClick={() => {
                setShow(true);
              }}>
                Add Plan
              </button>
            </>
          }
        />
      </div>

      <Offcanvas show={show} setShow={setShow} title="Add Course">
        <AddPlans />
      </Offcanvas>
    </>
  );
}

export default Plans;
