import {
  mdiChevronRight,
  mdiMagnify,
  mdiChevronDoubleLeft,
  mdiChevronLeft,
  mdiChevronDoubleRight,
  mdiChevronDown,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getAdvocateDelete, getAdvocateDetail } from "../config/api";
import { check_islogin } from "../config/constant";
import Table from "../component/Table";
import Offcanvas from "../component/Offcanvas";
import ImportAdvocate from "./ImportAdvocate";
import Pagination from "../component/Pagination";

function Advocates() {
  // const advProfile = [
  //   {
  //     name: "Annabellae Joe",
  //     designation: "Advocates",
  //     btn: "View More",
  //   },
  // ];
  let PageSize = 10;
  // const [data, setData] = useState([]);
  const [advocatedata, setAdvocateData] = useState([]);
  const [show, setShow] = useState(false);
  const [state, setState] = useState({ SearchText: "0" });
  useEffect(() => {
    check_islogin();
    bindData();
    console.log(1);
  }, []);


  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return advocatedata?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, advocatedata]);
  async function bindData() {
    await getAdvocateDetail(state)
      .then((response) => {
        debugger
        if (response.length > 0) {
          setAdvocateData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  function fnSearchText(e) {
    setState({ ...state, SearchText: e.target.value });
    bindData();
  }
  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Advocates</p>
      </div>
      {/* <div className="bg-white p-6 border shadow-sm rounded mt-5">

        <button
          className="bg-violet-900 text-white text-sm p-2 px-4 rounded font-medium"
          onClick={() => {
            //setEditID(0);
            setShow(true);
          }}
        >
          Import Advocate
        </button>


        {/* <div className="dataTables_wrapper dt-bootstrap5 no-footer">
            {data ? <Table columns={columns} data={data} /> : <></>}
          </div> 
      </div> */}
      {/* <Offcanvas show={show} setShow={setShow} title="Import Data">
        <ImportAdvocate setShow={setShow} />
      </Offcanvas> */}
      <div className="grid grid-cols-1 md:grid-cols-2 pt-5 gap-5">
        <div className="bg-white rounded-md p-2 flex justify-between">
          {/* <p className="text-sm text-slate-500">Search..</p> */}
          <input type="text" className="w-full" placeholder="Search"
            onChange={(e) => { fnSearchText(e) }} />
          <Icon path={mdiMagnify} className="w-5 h-5  text-violet-900" />
        </div>
        {/* <div>
          <p className="text-left md:text-right text-sm text-slate-500">
            Showing 1 to 10 0f 150 entries
          </p>
        </div> */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-5">
        {currentTableData && currentTableData?.map((state, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={state.RegistrationNumber}
              designation={state.FullName}
              advocateid={state.AdvocateID}
            />
          </div>
        ))}
        {/* {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}

        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}

        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}

        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}
        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}
        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}
        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))}
        {advProfile?.map((box, idx) => (
          <div className="bg-white rounded p-4" key={idx}>
            <AdvocateProfile
              name={box.name}
              designation={box.designation}
              btn={box.btn}
            />
          </div>
        ))} */}
      </div>
      <div className="grid grid-cols-2 pt-10 ">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={advocatedata.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
        {/* <div className="flex space-x-5 text-slate-700 place-items-center">
          <Icon path={mdiChevronDoubleLeft} className="w-5 h-5" />
          <Icon path={mdiChevronLeft} className="w-5 h-5" />
          <div className="flex space-x-2">
            <p>...</p>
            <div className="bg-white py-1 px-3 rounded">
              <p>1</p>
            </div>
            <div className="hover:bg-white py-1 px-3 rounded">
              <p>2</p>
            </div>
            <div className="hover:bg-white py-1 px-3 rounded">
              <p>3</p>
            </div>
            <p>...</p>
          </div>
          <Icon path={mdiChevronRight} className="w-5 h-5" />
          <Icon path={mdiChevronDoubleRight} className="w-5 h-5" />
        </div>

        <div className="flex justify-end">
          <div className="flex px-4 bg-white rounded place-items-center">
            <div>
              <p className="">10</p>
            </div>
            <div>
              <Icon path={mdiChevronDown} className="w-5 h-5" />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

function AdvocateProfile({ name, designation, advocateid, btn }) {
  async function deleteData(id) {
    if(window.confirm("Are you sure.?")){
    await getAdvocateDelete(id)
      .then((response) => {
        debugger;
        if (response.length > 0) {
          if (response[0]?.ID) {
            window.location.reload(true);
          } else {
            alert("error occured");
          }
        } else {
          alert("error occured");
        }
      })
      .catch((error) => {
        alert(error);
      });
    }
  }
  return (
    <>
      <div className="bg-white rounded p-4">
        <div className="flex justify-between">
          <div className="flex space-x-4">
            <div>
              <img
                src="/images/pic.jpg"
                alt=""
                className="w-12 h-12 rounded-full"
              />
            </div>
            <div className="pt-1">
              <p className="text-sm font-semibold text-slate-700">{name}</p>
              <p className="text-xs text-slate-500">{designation}</p>
            </div>
          </div>
          <div className="grid place-items-center">
            <button className="text-sm px-2.5 py-1.5 rounded text-white font-semibold bg-violet-900"
              onClick={() => { window.location.href = "/app/advocate-view?id=" + advocateid }}>
              {/* {btn} */}
              view more
            </button>
            <button className="text-sm px-2.5 py-1.5 rounded text-white font-semibold bg-violet-900"
              onClick={() => deleteData(advocateid)}>
              {/* {btn} */}
              delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Advocates;
