import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCompanyDetailByID, useQuery } from "../config/api";
import { check_islogin } from "../config/constant";

function CompViewMore() {
  const [companydata, setCompanyData] = useState([]);
  const query = useQuery();
  //console.log(query.get("id"))
  useEffect(() => {
    check_islogin();
    if (query.get("id") !== "0" && query.get("id") !== 0 && typeof query.get("id") !== undefined && query.get("id") !== "undefined") {
      bindData();
    }
  }, []);

  async function bindData() {
    await getCompanyDetailByID(query.get("id"))
      .then((response) => {
        if (response.length > 0) {
          setCompanyData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-slate-500 text-xl font-semibold">Company</p>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Company Details</p>
      </div>

      {companydata && companydata.length > 0 ? companydata.map((item, idex) => (<div className="pt-10">
        <div className="bg-white p-6 border space-y-10 shadow-sm rounded">
          <p className="text-violet-900 text-xl font-semibold">
            Company Details
          </p>
          <div className="space-y-5">
            <div className="grid grid-cols-4">
              {/* <div>
                <p className="text-slate-500 text-sm">Company ID</p>
                <p className="font-medium">MAH/1234/1234</p>
              </div> */}
              <div>
                <p className="text-slate-500 text-sm">Registration Number</p>
                <p className="font-medium">{item.RegistrationNumber}</p>
              </div>
            </div>
            <div className="grid grid-cols-4">
              <div>
                <p className="text-slate-500 text-sm">Company Name</p>
                <p className="font-medium">{item.CompanyName}</p>
              </div>
              <div>
                <p className="text-slate-500 text-sm">Email ID</p>
                <p className="font-medium">{item.EmailID}</p>
              </div>
            </div>
            <div className="grid grid-cols-4">
              <div>
                <p className="text-slate-500 text-sm">GST Number</p>
                <p className="font-medium">{item.GSTNumber}</p>
              </div>
              <div>
                <p className="text-slate-500 text-sm">Company Website</p>
                <p className="font-medium">{item.CompanyWebsite}</p>
              </div>
            </div>
            <div className="grid grid-cols-4">
              <div>
                <p className="text-slate-500 text-sm">Address</p>
                <p className="font-medium">{item.CompanyAddress}</p>
              </div>
            </div>
          </div>
          <div className="space-y-10">
            <p className="text-violet-900 text-xl font-semibold">
              Contact Details
            </p>
            <div className="space-y-5">
              {/* <div className="grid grid-cols-4">
                <div>
                  <p className="text-slate-500 text-sm">ID</p>
                  <p className="font-medium">MAH/1234/1234</p>
                </div>
                <div>
                  <p className="text-slate-500 text-sm">Company ID</p>
                  <p className="font-medium">MAH1234-1234</p>
                </div>
              </div> */}
              <div className="grid grid-cols-4">
                <div>
                  <p className="text-slate-500 text-sm">Full Name</p>
                  <p className="font-medium">{item.ContactPersonName}</p>
                </div>
                <div>
                  <p className="text-slate-500 text-sm">Email ID</p>
                  <p className="font-medium">{item.ContactPersonEmailID}</p>
                </div>
                <div>
                  <p className="text-slate-500 text-sm">Mobile Number</p>
                  <p className="font-medium">{item.ContactPersonMobile}</p>
                </div>
              </div>
              <div className="grid grid-cols-4">
                <div>
                  <p className="text-slate-500 text-sm">Gender</p>
                  <p className="font-medium">{item.ContactPersonGender}</p>
                </div>
                <div>
                  <p className="text-slate-500 text-sm">Address</p>
                  <p className="font-medium">
                    {item.ContactPersonAddress}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-4">
                <div>
                  <p className="text-slate-500 text-sm">City ID</p>
                  <p className="font-medium">{item.ContactPersonCity}</p>
                </div>
                <div>
                  <p className="text-slate-500 text-sm">Pin Code</p>
                  <p className="font-medium">{item.ContactPersonPincode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))
      : ""}
    </>
  );
}

export default CompViewMore;
