import React, { useMemo, useState ,useEffect} from "react";
import { mdiChevronRight, mdiPencil, mdiPlus, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import Table from "../../component/Table";
import Offcanvas from "../../component/Offcanvas";
import AddSpecilization from "./AddSpecilization";
import {
  iuspecialization,
  getSpecializationForDDL,
  getSpecializationDetail,
  getSpecializationDelete,
  getSpecializationDetailByID,
 } from "../../config/api";
import { check_islogin } from "../../config/constant";


function Specialization({ setSmShow, editID ,handleAddNew, handleEdit}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [editid, setEditID] = useState(0);
  const [state, setState] = useState({
    SpecializationID: "0",
    SpecializationName: "",
    CourseID:"",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  // const [show, setShow] = useState(false);
  // const data = [
  //   {
  //     id: "s101",
  //     specialization: "Specialization here",
  //     action: "action",
  //     course: "Course Name",
  //   },
  //   {
  //     id: "s102",
  //     specialization: "Specialization here",
  //     action: "action",
  //     course: "Course Name",
  //   },
  //   {
  //     id: "s103",
  //     specialization: "Specialization here",
  //     action: "action",
  //     course: "Course Name",
  //   },
  // ];
  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "SpecializationID",
    },
    {
      Header: "Specialization",
      accessor: "SpecializationName",
    },
    {
      Header: "Course Name",
      accessor: "CourseID",
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="flex justify-start space-x-3">
          {/* <button className="flex bg-violet-900 py-1 px-3 text-white rounded">
            <Icon path={mdiPlus} className="w-5 h-5" />
            <span className="text-xs text-center pt-0.5">People</span>
          </button> */}
          <button className="">
            <Icon path={mdiPencil} className="text-green-700 w-5 h-5" 
              onClick={() => {debugger;
               // UpdateData(row);
               setEditID(row.original.CourseID);setShow(true);
              }}
              
              // data-id={state.SpecializationID}
              // data-name="specialization"
              //onClick={handleEdit}
              />
          </button>
          <button className="">
            <Icon path={mdiTrashCan} className="text-red-600 w-5 h-5"
             onClick={() => {debugger;
              DeleteData(row);
            }}
            />
          </button>
        </div>
      ),
    },
  ]);

 // useEffect(() => {
    check_islogin();
    bindData();
 // }, []);

  async function bindData() {
    await getSpecializationDetail()
      .then((response) => {
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function DeleteData(row) {
    let _id = row.original.SpecializationID ;
    if (window.confirm("Are you sure to delete.?")) {
      await getSpecializationDelete(_id)
        .then((response) => {
          alert("Deleted Successfully");
          bindData();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function UpdateData(id) {
    await getSpecializationDetailByID(id)
      .then((response) => {
        setState(response[0][0]);
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      SpecializationID: "0",
      SpecializationName: "",
      CourseID:"",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-slate-500 text-xl font-semibold">Master</p>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Specialization</p>
      </div>
      <div className="bg-white p-6 border shadow-sm rounded mt-5">
        <Table
          columns={columns}
          data={data}
          leftSide={
            <>
              <button className="bg-violet-900 text-white text-sm p-2 px-4 rounded font-medium"
              onClick={() => {
                setEditID(0);
                setShow(true);
              }}>
                Add Specialization
              </button>
            </>
          }
        />
      </div>
      <Offcanvas show={show} setShow={setShow} title="Add Specialization">
        <AddSpecilization editID={editid} setShow={setShow}/>
      </Offcanvas>
    </>
  );
}

export default Specialization;
