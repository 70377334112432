import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiChevronRight,
  mdiAccountOutline,
  mdiDomain,
  mdiBellOutline,
} from "@mdi/js";
import { Link } from "react-router-dom";
import { getDashboard } from "../config/api";
import { check_islogin } from "../config/constant";

function Dashboard() {
  const [data, setDate] = useState({ TotalAdvocate: "0", TotalCompany: "0" });
  useEffect(() => {
    check_islogin();
    bindData();
  }, []);

  async function bindData() {
    debugger;
    await getDashboard()
      .then((response) => {
        if (response.length > 0) {
          setDate({
            TotalAdvocate: response[0][0].TotalAdvocate,
            TotalCompany: response[0][0].TotalCompany,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Dashboard</p>
      </div>
      <div className="pt-5 grid grid-cols-4 gap-5">
        <div className="text-violet-900 hover:bg-violet-300 space-y-3 bg-white shadow-md shadow-violet-200 border border-violet-200 rounded p-5">
          <div className="pl-4 text-xl font-semibold">{data.TotalAdvocate}</div>
          <div className="flex pl-3">
            <Icon path={mdiAccountOutline} className="w-8 h-8" />
            <p className="text-2xl">Advocates</p>
          </div>
        </div>
        <div className="text-violet-900 hover:bg-violet-300 space-y-3 space-x-2 bg-white shadow-md shadow-violet-200 border border-violet-200 rounded p-5">
          <div className="pl-2 text-xl font-semibold">{data.TotalCompany} </div>
          <div className="flex">
            <Icon path={mdiDomain} className="w-8 h-8" />
            <p className="text-2xl">Companies</p>
          </div>
        </div>
        <div className="flex text-violet-900 hover:bg-violet-300 space-x-2 bg-white shadow-md shadow-violet-200 border border-violet-200 rounded p-5">
          <Icon path={mdiBellOutline} className="w-8 h-8 mt-4" />
          <p className="text-2xl pt-4">Notifications</p>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
