import React from "react";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiCircleSmall } from "@mdi/js";
import { Link } from "react-router-dom";

function Notification() {
  const note = [
    {
      name: "Annabellae Joe",
      time: "3 min ago",
      date: "16/11/2022",
      msg: "Hello, I am Annaebella !... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
    },
  ];

  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
        <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Notifications</p>
      </div>

      <div className="pt-5">
        <div className="bg-white py-2 px-3 rounded-lg text-slate-600 w-52">
          <input type="date" />
        </div>
      </div>

      <div className="pt-5 grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="col-start-1 cols-end-2">
          {note?.map((n, idx) => (
            <div className="bg-white rounded border" key={idx}>
              <NoteProfile
                name={n.name}
                time={n.time}
                date={n.date}
                msg={n.msg}
              />
            </div>
          ))}
        </div>
        <div className="col-start-1 cols-end-2">
          {note?.map((n, idx) => (
            <div className="bg-white rounded border" key={idx}>
              <NoteProfile
                name={n.name}
                time={n.time}
                date={n.date}
                msg={n.msg}
              />
            </div>
          ))}
        </div>
        <div className="col-start-1 cols-end-2">
          {note?.map((n, idx) => (
            <div className="bg-white rounded border" key={idx}>
              <NoteProfile
                name={n.name}
                time={n.time}
                date={n.date}
                msg={n.msg}
              />
            </div>
          ))}
        </div>
        <div className="col-start-1 cols-end-2">
          {note?.map((n, idx) => (
            <div className="bg-white rounded border" key={idx}>
              <NoteProfile
                name={n.name}
                time={n.time}
                date={n.date}
                msg={n.msg}
              />
            </div>
          ))}
        </div>
        <div className="col-start-1 cols-end-2">
          {note?.map((n, idx) => (
            <div className="bg-white rounded border" key={idx}>
              <NoteProfile
                name={n.name}
                time={n.time}
                date={n.date}
                msg={n.msg}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function NoteProfile({ name, time, date, msg }) {
  return (
    <>
      <div className="bg-white p-2">
        <div className="flex justify-between">
          <div className="flex space-x-3">
            <div>
              <img
                src="/images/pic.jpg"
                alt=""
                className="rounded-full w-10 h-10"
              />
            </div>
            <div className="">
              <p className="text-md font-semibold">{name}</p>
              <div className="flex">
                <Icon path={mdiCircleSmall} className="w-5 h-5 m-0" />
                <p className="list-disc list-inside text-sm text-slate-600">
                  {time}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <p className="text-xs text-slate-600">{date}</p>
          </div>
        </div>
        <div className="px-16 pt-3">
          <div className="bg-slate-100 rounded-lg p-2">
            <p className="text-sm">{msg}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
