import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import {
  mdiChevronRight,
  mdiMagnify,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiChevronLeft,
  mdiChevronDown,
} from "@mdi/js";
import { Link } from "react-router-dom";
import { getCompanyDelete, getCompanyDetail } from "../config/api";
import { check_islogin } from "../config/constant";

function Companies() {
  // const company = [
  //   {
  //     name: "DThrill Software Solution",
  //     city: "Pune",
  //     email: "example@gmail.com",
  //     contact: "+91 9612453678",
  //   },
  // ];
  const [data, setData] = useState([]);
  const [companydata, setCompanyData] = useState([]);
  useEffect(() => {
    check_islogin();
    bindData();
  }, []);
  async function bindData() {
    debugger;
    await getCompanyDetail()
      .then((response) => {
        if (response.length > 0) {
          setCompanyData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div className="flex space-x-1">
        <span className="text-slate-500 text-xl hover:text-blue-700 hover:underline font-semibold">
          <Link to="/app/dashboard">Home</Link>
        </span>
        <Icon path={mdiChevronRight} className="w-6 h-6 pt-1 text-slate-500" />
        <p className="text-violet-900 text-xl font-semibold">Companies</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 pt-5 gap-5">
        <div className="bg-white rounded-md p-2 w-52 flex justify-between">
          <p className="text-sm text-slate-500">Search..</p>
          <Icon path={mdiMagnify} className="w-5 h-5  text-violet-900" />
        </div>
        <div>
          <p className="text-left md:text-right text-sm text-slate-500">
            Showing 1 to 10 0f 150 entries
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-5">
        {companydata?.map((state, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={state.CompanyName}
              city={state.Address}
              email={state.EmailID}
              contact={state.CompanyWebsite}
              companyid={state.CompanyID}
            />
          </div>
        ))}
        {/* {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))}
        {company?.map((com, idx) => (
          <div className="bg-white rounded border" key={idx}>
            <CompanyProfile
              name={com.name}
              city={com.city}
              email={com.email}
              contact={com.contact}
            />
          </div>
        ))} */}
      </div>

      <div className="grid grid-cols-2 pt-10 ">
        <div className="flex space-x-5 text-slate-700 place-items-center">
          <Icon path={mdiChevronDoubleLeft} className="w-5 h-5" />
          <Icon path={mdiChevronLeft} className="w-5 h-5" />
          <div className="flex space-x-2">
            <p>...</p>
            <div className="bg-white py-1 px-3 rounded">
              <p>1</p>
            </div>
            <div className="hover:bg-white py-1 px-3 rounded">
              <p>2</p>
            </div>
            <div className="hover:bg-white py-1 px-3 rounded">
              <p>3</p>
            </div>
            <p>...</p>
          </div>
          <Icon path={mdiChevronRight} className="w-5 h-5" />
          <Icon path={mdiChevronDoubleRight} className="w-5 h-5" />
        </div>

        <div className="flex justify-end">
          <div className="flex px-4 bg-white rounded place-items-center">
            <div>
              <p className="">10</p>
            </div>
            <div>
              <Icon path={mdiChevronDown} className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CompanyProfile({ name, city, email, contact,companyid, btn }) {
  async function deleteData(id) {
    if(window.confirm("Are you sure.?")){
    await getCompanyDelete(id)
      .then((response) => {
        debugger;
        if (response.length > 0) {
          if (response[0]?.ID) {
            window.location.reload(true);
          } else {
            alert("error occured");
          }
        } else {
          alert("error occured");
        }
      })
      .catch((error) => {
        alert(error);
      });
    }
  }
  return (
    <div className="bg-white rounded border">
      <div className="p-4">
        <div className="flex space-x-4">
          <div>
            <img
              src="/images/company name.png"
              alt=""
              className="w-24 h-16 rounded-full"
            />
          </div>
          <div className="pt-3">
            <p className="text-sm font-semibold text-slate-700">{name}</p>
            <p className="text-xs text-slate-500 ">{city}</p>
          </div>
        </div>
        <div className="pt-4">
          <div className="flex space-x-2">
            <p className="text-sm text-slate-700 font-semibold">Email Id:</p>
            <p className="text-sm text-slate-700">{email}</p>
          </div>
          <div className="flex space-x-2">
            <p className="text-sm text-slate-700 font-semibold">Contact No.:</p>
            <p className="text-sm text-slate-700">{contact}</p>
          </div>
        </div>
      </div>
      <div className="grid place-items-end px-4 py-2 bg-slate-50 rounded-b border-t">
        <button
          className="text-sm px-2.5 py-1.5 rounded text-white font-semibold bg-violet-900"
          onClick={() => {
            window.location.href = "/app/company-view?id="+companyid;
          }}
        >
          View More
        </button>
        <button className="text-sm px-2.5 py-1.5 rounded text-white font-semibold bg-violet-900"
              onClick={() => deleteData(companyid)}>
              {/* {btn} */}
              delete
            </button>
      </div>
    </div>
  );
}

export default Companies;
