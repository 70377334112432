//import React from 'react'
import React , { useEffect, useState }from "react";
import {
  iuplan,
  getPlanForDDL,
  getPlanDetail,
  getPlanDelete,
  getPlanDetailByID,
 } from "../config/api";
import { check_islogin } from "../config/constant";

 
function AddPlans() {


  const [state, setState] = useState({
    PlanID: "0",
      PlanName: "",
      SubTitle:"",
      Price:"",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });

  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iuplan(state)
        .then((response) => {
          if (response.ID !== "exists") {
            alert("Plan Name Saved Successfully");
            ResetState();
            window.location.reload(true);
            // navigate(
            //   `/app/package/add/itinerary${
            //     location.search.includes("m=e")
            //       ? location.search
            //       : "?id=" + response.ID
            //   }`
            // );
          } else {
            errors["PlanName"] = "This Plan Already exists...";
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function ResetState() {
    setState({
      PlanID: "0",
      PlanName: "",
      SubTitle:"",
      Price:"",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.PlanName) {
      IsValid = false;
      errors["PlanName"] = "Plan Name is Required";
    }
    if (!state?.SubTitle) {
      IsValid = false;
      errors["SubTitle"] = "SubTitle is Required";
    }
    // if (!state?.Price) {
    //   IsValid = false;
    //   errors["Price"] = "Price is Required";
    // }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

useEffect(()=>{check_islogin();},[])


  return (
    <>
       <div className="space-y-1">
          <p className="text-sm">Plan Name</p>
          <input type="text" 
         label="Plan Name"
         name="PlanName"
         data-name="PlanName"
         value={state.PlanName}
         onChange={handlechange}
         className="border border-slate-400 p-1 rounded w-full"
         error={
          state.errors
            ? state.errors.PlanName
              ? state.errors.PlanName
              : ""
            : ""
        }
         />
        </div>
        <div className="space-y-1 pt-5">
        <p className="text-sm">Sub Title</p>
        <textarea
          rows="3"
          type="text"
          label="Sub Title"
           name="SubTitle"
           data-name="SubTitle"
           value={state.SubTitle}
           onChange={handlechange}
          className="border border-slate-400 p-1 rounded w-full"
          error={
            state.errors
              ? state.errors.SubTitle
                ? state.errors.SubTitle
                : ""
              : ""
          }
        />
      </div>
      <div className="space-y-1 pt-5">
        <p className="text-sm">Price</p>
        <select
          className="border border-slate-400 text-slate-600 text-sm p-1 rounded w-full">
            <option selected>month/year</option>
          </select>
      </div>
        <div className="flex justify-center space-x-2 pt-10 mx-auto">
            <button onClick={SaveData}
            className="bg-violet-900 text-white py-1 px-10 rounded">Submit</button>
            <button onClick={ResetState}
            className="bg-yellow-500 text-white py-1 px-10 rounded">Reset</button>
        </div>
    </>
  )
}

export default AddPlans
