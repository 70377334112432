import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { API_URL, baseURL } from "./constant";
export const axiosInstance = axios.create({
  baseURL: baseURL,
});

//import { resolve } from "./resolve.js";
export default () => axios.get("/");

// //https://api.abhitalandsolutions.com
//http://localhost:5001
// //const API_URL="https://api.legavac.com/api/legavac";
//const API_URL="http://localhost:5001/api/advocate";


export const useQuery = () => { 
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

//UserMaster
export async function getLoginDetail(state) {
  return await axiosInstance
    .post(`${API_URL}/getlogindetail`, state)
    .then((res) => res.data);
}
export async function forgotPassword(state) {
  return await axiosInstance
    .post(`${API_URL}/forgotPassword`, state)
    .then((res) => res.data);
}

export async function getAdminLoginDetail(state) {
  return await axios
    .post(`${API_URL}/getAdminLoginDetail`, state)
    .then((res) => res.data);
}
export async function getDashboard() {
  return await axios.get(`${API_URL}/getDashboard`).then((res) => res.data);
}

//advocate_App
export async function iuadvocate_app(state) {
  return await axiosInstance
    .post(`${API_URL}/iuadvocate_app`, state)
    .then((res) => res.data);
}

export async function getAdvocateForDDL_app() {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateForDDL_app`)
    .then((res) => res.data);
}

export async function getAdvocateDetail_app() {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateDetail_app`)
    .then((res) => res.data);
}

export async function getAdvocateDetailByID_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateDetailByID_app/${id}`)
    .then((res) => res.data);
}

export async function getAdvocateDetailForCompany_app() {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateDetailForCompany_app`)
    .then((res) => res.data);
}

//advocate

export async function getAdvocateForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateForDDL`)
    .then((res) => res.data);
}

export async function getAdvocateDetail(state) {
  return await axiosInstance
    .post(`${API_URL}/getAdvocateDetail`,state)
    .then((res) => res.data);
}

export async function getAdvocateDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateDelete/${id}`)
    .then((res) => res.data);
}

export async function getAdvocateDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getAdvocateDetailByID/${id}`)
    .then((res) => res.data);
}

//education

//app
//education
export async function iueducation_app(state) {
  return await axiosInstance
    .post(`${API_URL}/iueducation_app`, state)
    .then((res) => res.data);
}

export async function getEducationDetail_app() {
  return await axiosInstance
    .get(`${API_URL}/getEducationDetail_app`)
    .then((res) => res.data);
}

export async function getEducationDelete_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getEducationDelete_app/${id}`)
    .then((res) => res.data);
}

export async function getEducationDetailByID_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getEducationDetailByID_app/${id}`)
    .then((res) => res.data);
}

//company

export async function getCompanyForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getCompanyForDDL`)
    .then((res) => res.data);
}

export async function getCompanyDetail() {
  return await axiosInstance
    .get(`${API_URL}/getCompanyDetail`)
    .then((res) => res.data);
}

export async function getCompanyDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getCompanyDelete/${id}`)
    .then((res) => res.data);
}

export async function getCompanyDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getCompanyDetailByID/${id}`)
    .then((res) => res.data);
}

//company
export async function iucompany_app(state) {
  return await axiosInstance
    .post(`${API_URL}/iucompany_app`, state)
    .then((res) => res.data);
}

export async function getCompanyForDDL_app() {
  return await axiosInstance
    .get(`${API_URL}/getCompanyForDDL_app`)
    .then((res) => res.data);
}

export async function getCompanyDetail_app() {
  return await axiosInstance
    .get(`${API_URL}/getCompanyDetail_app`)
    .then((res) => res.data);
}

export async function getCompanyProfileByID_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getCompanyProfileByID_app/${id}`)
    .then((res) => res.data);
}

//app
//contactdetails
export async function iucontactdetails_app(state) {
  return await axiosInstance
    .post(`${API_URL}/iucontactdetails_app`, state)
    .then((res) => res.data);
}

export async function contactaddressdetail_app(state) {
  return await axiosInstance
    .post(`${API_URL}/contactaddressdetail_app`, state)
    .then((res) => res.data);
}

export async function getContactdetailsDetail_app() {
  return await axiosInstance
    .get(`${API_URL}/getContactdetailsDetail_app`)
    .then((res) => res.data);
}

export async function getContactdetailsDetailByID_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getContactdetailsDetailByID_app/${id}`)
    .then((res) => res.data);
}

//Course
export async function iucourse(state) {
  return await axiosInstance
    .post(`${API_URL}/iucourse`, state)
    .then((res) => res.data);
}

export async function getCourseForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getCourseForDDL`)
    .then((res) => res.data);
}

export async function getCourseDetail() {
  return await axiosInstance
    .get(`${API_URL}/getCourseDetail`)
    .then((res) => res.data);
}

export async function getCourseDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getCourseDelete/${id}`)
    .then((res) => res.data);
}

export async function getCourseDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getCourseDetailByID/${id}`)
    .then((res) => res.data);
}

//app
//Course

export async function getCourseForDDL_app() {
  return await axiosInstance
    .get(`${API_URL}/getCourseForDDL_app`)
    .then((res) => res.data);
}

export async function getCourseDelete_app(id) {
  return await axiosInstance
    .get(`${API_URL}/getCourseDelete_app/${id}`)
    .then((res) => res.data);
}

//Specialization
export async function iuspecialization(state) {
  return await axiosInstance
    .post(`${API_URL}/iuspecialization`, state)
    .then((res) => res.data);
}

export async function getSpecializationDetail() {
  return await axiosInstance
    .get(`${API_URL}/getSpecializationDetail`)
    .then((res) => res.data);
}

export async function getSpecializationDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getSpecializationDelete/${id}`)
    .then((res) => res.data);
}

export async function getSpecializationDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getSpecializationDetailByID/${id}`)
    .then((res) => res.data);
}

//app

//Specialization

export async function getSpecializationForDDL_app() {
  return await axiosInstance
    .get(`${API_URL}/getSpecializationForDDL_app`)
    .then((res) => res.data);
}

//Plan
export async function iuplan(state) {
  return await axiosInstance
    .post(`${API_URL}/iuplan`, state)
    .then((res) => res.data);
}

export async function getPlanDetail() {
  return await axiosInstance
    .get(`${API_URL}/getPlanDetail`)
    .then((res) => res.data);
}

export async function getPlanDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getPlanDelete/${id}`)
    .then((res) => res.data);
}

export async function getPlanDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getPlanDetailByID/${id}`)
    .then((res) => res.data);
}

//app
//Plan

export async function getPlanDetail_app() {
  return await axiosInstance
    .get(`${API_URL}/getPlanDetail_app`)
    .then((res) => res.data);
}

//For Continent
export async function getContinentForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getContinentForDDL`)
    .then((res) => res.data);
}

//ForCountry
export async function iucountry(state) {
  return await axiosInstance
    .post(`${API_URL}/iucountry`, state)
    .then((res) => res.data);
}
export async function getCountryForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getCountryForDDL`)
    .then((res) => res.data);
}
export async function getCountryDetail() {
  return await axiosInstance
    .get(`${API_URL}/getCountryDetail`)
    .then((res) => res.data);
}
export async function getCountryDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getCountryDetailByID/${id}`)
    .then((res) => res.data);
}
export async function getCountryDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getCountryDelete/${id}`)
    .then((res) => res.data);
}

//ForState
export async function iustate(state) {
  return await axiosInstance
    .post(`${API_URL}/iustate`, state)
    .then((res) => res.data);
}
export async function getStateForDDL(countryid) {
  return await axiosInstance
    .get(`${API_URL}/getStateForDDL/${countryid}`)
    .then((res) => res.data);
}
export async function getStateDetail() {
  return await //resolve(
  axiosInstance.get(`${API_URL}/getStateDetail`).then((res) => res.data);
}
export async function getStateDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getStateDetailByID/${id}`)
    .then((res) => res.data);
}
export async function getStateDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getStateDelete/${id}`)
    .then((res) => res.data);
}

//ForCity
export async function iucity(state) {
  return await axiosInstance
    .post(`${API_URL}/iucity`, state)
    .then((res) => res.data);
}
export async function getCityForDDL(stateid) {
  return await axiosInstance
    .get(`${API_URL}/getCityForDDL/${stateid}`)
    .then((res) => res.data);
}
export async function getCityDetail() {
  return await //resolve(
  axiosInstance.get(`${API_URL}/getCityDetail`).then((res) => res.data);
}
export async function getCityDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getCityDetailByID/${id}`)
    .then((res) => res.data);
}
export async function getCityDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getCityDelete/${id}`)
    .then((res) => res.data);
}
