import React from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
} from "react-table";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex space-x-2">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        className="rounded text-sm py-1.5 px-3 capitalize border shadow-sm "
        placeholder="search here..."
      />
    </div>
  );
}
export default function Table({ columns, data, leftSide }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <>
      <div className="row">
        <div className="flex justify-between items-center">
          {leftSide}
          <div className="">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>
      </div>
      <div className=" pt-5">
        <table {...getTableProps()} className="w-full text-left">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="border-b border-violet-200"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="p-3"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ▼"
                          : " ▲"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-violet-200"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td className="p-3 text-left" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between pt-10">
        <div className="grid grid-col-2 gap-5">
          <div className="space-x-1">
            <span className="text-sm">Show</span>
            <select
              value={state.pageSize}
              className="rounded text-sm"
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span className="text-sm"> entries</span>
          </div>
        </div>
        <ul className="flex space-x-5">
          <li
            className={
              "paginate_button page-item" +
              (!canPreviousPage ? " disabled" : "")
            }
          >
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="page-link"
            >
              {"<<"}
            </button>
          </li>
          <li
            className={
              "paginate_button page-item" +
              (!canPreviousPage ? " disabled" : "")
            }
          >
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="page-link"
            >
              {"<"}
            </button>
          </li>
          <li className="d-flex align-items-center px-3">
            <span>
              Page{" "}
              <strong>
                {state.pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </li>
          <li
            className={
              "paginate_button page-item" + (!canNextPage ? " disabled" : "")
            }
          >
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="page-link"
            >
              {">"}
            </button>
          </li>
          <li
            className={
              "paginate_button page-item" + (!canNextPage ? " disabled" : "")
            }
          >
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="page-link"
            >
              {">>"}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
