import React, { useEffect, useState } from "react";
import {
  iuspecialization,
  getSpecializationForDDL,
  getSpecializationDetail,
  getSpecializationDelete,
  getSpecializationDetailByID,
  getCourseForDDL,
} from "../../config/api";
import { check_islogin } from "../../config/constant";

function AddSpecilization({ setShow, editID }) {

  const [courdeddl, setCoursedll] = useState([]);
  const [state, setState] = useState({
    SpecializationID: "0",
    SpecializationName: "",
    CourseID: "",
    CreatedBy: sessionStorage.getItem("UserID"),
    errors: [],
  });
  useEffect(() => {
    debugger;
    // BindEducationDDL();
    check_islogin();

    bindCourseDLL();
    if (editID !== "" && editID !== "0" && editID !== 0 && typeof editID !== "undefined") {
      UpdateData(editID);
    } else {
      ResetState();
    }
  }, [editID]);

  //}, []);
  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  async function bindCourseDLL() {
    await getCourseForDDL()
      .then((response) => {
        console.log(response[0])
        setCoursedll(response);
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function SaveData() {
    debugger;
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iuspecialization(state)
        .then((response) => {
          if (response.ID !== "exists") {
            if (editID === "0" || editID === 0) {
              alert("Specialization Name Saved Successfully");
            } else {
              alert("Course Name Updated Successfully");
            }
            setShow(false);
            ResetState();
            //  window.location.reload(true);
            // navigate(
            //   `/app/package/add/itinerary${
            //     location.search.includes("m=e")
            //       ? location.search
            //       : "?id=" + response.ID
            //   }`
            // );
          } else {
            errors["SpecializationName"] = "This Specialization Name Already exists...";
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function UpdateData(id) {
    await getSpecializationDetailByID(id)
      .then((response) => {
        setState(response[0]);
      })
      .catch((error) => {
        alert(error);
      });
  }



  async function ResetState() {
    setState({
      SpecializationID: "0",
      SpecializationName: "",
      CourseID: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.SpecializationName) {
      IsValid = false;
      errors["SpecializationName"] = "Specialization Name is Required";
    }
    if (!state?.CourseID) {
      IsValid = false;
      errors["CourseID"] = "Course Name is Required";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }


  return (
    <>
      <div className="space-y-1">
        <p className="text-sm">Specialization</p>
        <input
          type="text"
          label="SpecializationName"
          name="SpecializationName"
          data-name="SpecializationName"
          value={state.SpecializationName}
          onChange={handlechange}
          className="border border-slate-400 p-1 rounded w-full"
          error={
            state.errors
              ? state.errors.SpecializationName
                ? state.errors.SpecializationName
                : ""
              : ""
          }
        />
      </div>
      <div className="space-y-1 pt-5">
        <p className="text-sm">Course Name</p>
        <select className="border border-slate-400 p-1 rounded w-full"
          name="CourseID"
          value={state.CourseID}
          onChange={handlechange}>
          <option value="0">Select</option>
          {courdeddl.map((item, idx) => (
            <option value={item.CourseID}>{item.CourseName}</option>
          ))}
        </select>
        {/* <input
          type="text"
          label="Course Name"
          name="CourseID"
          data-name="CourseName"
          value={state.CourseID}
          onChange={handlechange}
          className="border border-slate-400 p-1 rounded w-full"
          error={
            state.errors
              ? state.errors.CourseID
                ? state.errors.CourseID
                : ""
              : ""
          }
        /> */}
      </div>
      <div className="flex justify-center space-x-2 pt-10 mx-auto">
        <button onClick={SaveData} className="bg-violet-900 text-white py-1 px-10 rounded">
          Submit
        </button>
        <button onClick={ResetState} className="bg-yellow-500 text-white py-1 px-10 rounded">
          Reset
        </button>
      </div>
    </>
  );
}

export default AddSpecilization;
